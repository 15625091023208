import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { RsaService } from '../common/rsa.service';

@Injectable()
export class EncryptionInterceptor implements HttpInterceptor {

  private readonly ENCRYPTION_REQUIRED_ROUTES = [
    '/user/login',
    '/user/register',
    '/user/login-name/',
    '/user/register/wellbet',
    '/user/account/password/change',
    '/user/forgot-password/verify',
    '/user/forgot-password/password/change',
    '/zeta/registration/login.wb'
  ];

  private readonly encryptedFields = [
    'loginName', 
    'password', 
    'confirmPassword', 
    'currentPassword'
  ];

  constructor(
    private encryptionService: RsaService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.shouldEncrypt(req)) {
      return this.encryptRequest(req).pipe(
        switchMap(encryptedReq => next.handle(encryptedReq))
      );
    }
    return next.handle(req);
  }

  private shouldEncrypt(req: HttpRequest<any>): boolean {
    return this.ENCRYPTION_REQUIRED_ROUTES.some(endpoint => req.url.endsWith(endpoint));
  }

  private encryptRequest(req: HttpRequest<any>): Observable<HttpRequest<any>> {
    const body = req.body;
    return this.encryptionService.encrypt(
      this.encryptedFields.map(field => body[field])
    ).pipe(
      switchMap(encryptedValues => {
        const encryptedBody = { ...body };
        this.encryptedFields.forEach((field, index) => {
          if (Object.prototype.hasOwnProperty.call(encryptedBody, field)) {
            encryptedBody[field] = encryptedValues[index]
          }
        });

        const encryptedReq = req.clone({
          body: encryptedBody
        });
        return from([encryptedReq]);
      })
    );
  }
}
