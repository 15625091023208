import { Location } from '@angular/common';
import { AfterViewInit, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { distinctUntilChanged, fromEvent, map } from 'rxjs';
import { ApiError, ErrorCodes } from './shared/models/http.model';
import { LocalizedMessages } from './shared/models/i18n.model';
import { CommonService } from './shared/service/common/common.service';
import { HttpService } from './shared/service/http/http.service';
import { PlatformService } from './shared/service/platform/platform.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements AfterViewInit {

  isMaintenance: boolean = false;
  titles: LocalizedMessages = {
    'zh-cn': '\u0057\u0042\u76F4\u64AD',
    'en-gb': 'WB LIVESTREAM'
  }

  constructor(
    private commonService: CommonService,
    private httpService: HttpService,
    private location: Location,
    private title: Title,
    private platformService: PlatformService
  ) {
    this.commonService.setLanguageFromUrl(this.location.path()).subscribe(language => {
      this.httpService.setLanguage(language);
    });

    this.commonService.initializeDomains().subscribe({
      next: (domains) => {
        this.httpService.setDomains(domains);
      },
      error: (errors: ApiError[]) => {
        if (errors.some(error => error.code == ErrorCodes.Maintenance)) {
          this.isMaintenance = true;
        }
      }
    });

    this.commonService.maintenanceToggle$.subscribe(isMaintenance => this.isMaintenance = isMaintenance);

    this.commonService.currentLanguage$.subscribe(currentLanguage => {
      this.title.setTitle(this.titles[currentLanguage]);
    });

  }

  ngAfterViewInit(): void {
    if(this.platformService.isBrowser()) {
      this.recalibrateViewportHeight();

      fromEvent(window, 'resize')
        .pipe(map(() => window.innerHeight), distinctUntilChanged())
        .subscribe(() => this.recalibrateViewportHeight());
    }
  }

  /**
   * In Android/IOS, the window height changes depending when the address bar is present or not
   * However, the css doesn't detect it so we need to add a resize event for it
   * 
   * see chat-conversation.component.scss .chat-conversation-wrapper height declaration
   * it uses dynamic height so we can preserve positioning in chat specially the bottom parts
   * to be scrollable
   */
  recalibrateViewportHeight() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--dynamic-vh', `${vh}px`);
  }
}
