import { Injectable } from '@angular/core';
import { catchError, forkJoin, from, Observable, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
declare const TextEncoder: any;

@Injectable({
  providedIn: 'root'
})
export class RsaService {

  // eslint-disable-next-line max-len
  private PUBLIC_KEY = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAjyCGRq5hONA9u4msMDBEvNHEYNoq9oXHkz1UiKBJpSIKPzPb+03CGlFIOopI9O3TnKiHm2vxiHz57iTHlJ8eM63SRlsf6DT0jaMb3+uqKZNVfSmrfW1M+bRXnYU/pcOKqYQp4OwRxwBFD67eOCPgfBBdFlaXf7WGAhAErK5fqSC8GYg8ZsoET5JjzbnRKGheOOyf24/ZXGWzLuneyDS15ApvXONHIxGE4BwR9/ntLwT969vPmmSFLPs3/CJ5eEMWAxphq0Jokv04duKKSt/yQr+sYeR5P9DWk35cXwRIESVae8L0sSeJ0rsmEoF0H7uZav35/nCQlCSe/EkrwJcJCwIDAQAB';

  constructor() { }

  encrypt(input: string | string[]): Observable<string[]> {
    const encryptionTasks = Array.isArray(input)
      ? input.map(msg => this.performEncryption(msg, this.PUBLIC_KEY))
      : [this.performEncryption(input, this.PUBLIC_KEY)];

    return forkJoin(encryptionTasks);
  }

  private performEncryption(message: string, publicKeyPem: string): Observable<string> {
    return this.importPublicKey(publicKeyPem).pipe(
      switchMap(publicKey => {
        const encodedMessage = new TextEncoder().encode(message);
        return from(window.crypto.subtle.encrypt(
          { name: 'RSA-OAEP' },
          publicKey,
          encodedMessage
        ));
      }),
      map(encryptedBuffer => this.arrayBufferToBase64(encryptedBuffer)),
      catchError(() => of(''))
    );
  }

  private importPublicKey(publicKeyPem: string): Observable<CryptoKey> {
    const publicKey = this.pemToBinary(publicKeyPem);
    return from(crypto.subtle.importKey(
      'spki',
      publicKey,
      { name: 'RSA-OAEP', hash: 'SHA-256' },
      false,
      ['encrypt']
    ));
  }

  private pemToBinary(pem: string): ArrayBuffer {
    const b64 = pem.replace(/-----(BEGIN|END) [A-Z ]+-----/g, '').replace(/\s+/g, '');
    const binary = atob(b64);
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < binary.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    return bytes.buffer;
  }

  private arrayBufferToBase64(buffer: ArrayBuffer): string {
    const binary = String.fromCharCode(...new Uint8Array(buffer));
    return btoa(binary);
  }
}
